import { createMuiTheme } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';

// Create a theme instance.
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#DC004E',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
    success: {
      main: '#4caf50',
      light: '#81c784',
      dark: '#388e3c',
    },
  },
  overrides: {
    MuiTextField: {
      root: {
        '& .MuiInputLabel-outlined.Mui-focused': {
          color: '#ff9800',
        },
        // '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        //   borderColor: 'green',
        // },
        '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
          borderColor: '#ff9800',
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: '#ff9800',
        },
      },
    },
  },
});

export default theme;
